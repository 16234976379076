import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPlus, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Col,
  Collapse,
  FormGroup,
  Row,
  Fade,
} from "reactstrap";
import Paginate from "./../common/pagination";
import usePost from "../../services/usePost";
import useGet from "../../services/useGet";
import SOURCES from "../../utils/constants";
import useDebounce from "../../utils/customHooks";
import UsersForm from "../forms/admin/usersForm";
import UserDetail from "../admin/modules/userDetail";
import AdvanceSearchUser from "./modules/advanceSearchUser";
import UserList from "./modules/userList";
import LocalStorageService from "../../utils/localStorageServices";
import { MSG_USER } from "../../utils/message";
import { yupValidationSchema } from "../../utils/functions";
import HeaderSearchInput from "../common/headerSearchInput";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../redux/actions";

const validationSchema = yupValidationSchema({
  name: { required: true, customName: "Name" },
  company: { required: true, customName: "Company" },
  phone: { required: true, customName: "Phone number" },
  email: { email: true, required: true, customName: "Email" },
  password: { required: true, customName: "Password" },
  confirmPassword: { required: true, customName: "Confirm password" },
  plan: { required: true, customName: "Plan" },
});

const Users = ({ showFormInitial = false }) => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING,
  });
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [advanceSearch, setAdvanceSearch] = useState({
    advance_search_enabled:
      SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
    plan: "",
    status: "",
    trial: "",
    userStatus: "",
    leadSource: "",
  });
  const [showForm, setShowForm] = useState(showFormInitial);
  const [userList, setUserlist] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [showDetail, setshowDeatail] = useState(false);
  const [leadDetail, setLeadDetail] = useState(null);
  const [deleteId, setDeleteId] = useState();
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const localStorageServices = LocalStorageService.getService();
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    setAdvanceSearch({
      ...advanceSearch,
      advance_search_enabled:
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
    });
    setShowAdvanceSearch(false);
  }, [searchInput]);

  const [userInfo, setUserInfo] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    plan: "",
    coupon: "",
  });

  const {
    callApi: getUsers,
    data: users,
    isLoading: usersLoading,
  } = useGet({
    endpoint: `admin/users?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoading,
  } = useGet({
    endpoint: `admin/users/search?q=${searchInput}`,
  });

  const {
    callApi: getAdvanceSearchData,
    data: advanceSearchData,
    isLoading: advanceSearchDataLoading,
  } = useGet({
    endpoint: `admin/users/search-advance?advance_search=${advanceSearch.advance_search_enabled}&plan=${advanceSearch.plan}&status=${advanceSearch.status}&trial=${advanceSearch.trial}&userStatus=${advanceSearch.userStatus}&leadSource=${advanceSearch.leadSource}`,
  });

  const { callApi: accountStatus, processSuccess } = usePost({
    endpoint: `admin/status`,
  });

  const { callApi: getPaymentPlans, data: paymentPlans } = useGet({
    endpoint: `payment/plans?includePrivatePlans=y`,
  });

  const { callApi: loginUser } = usePost({
    endpoint: `admin/login-as-user`,
  });

  const { callApi: removeUser, processSuccess: removeSuccess } = usePost({
    endpoint: `admin/remove-user`,
  });

  const deleteUser = async (id) => {
    await removeUser({ id }, MSG_USER.DELETE);
  };

  const loginAsUser = async (email) => {
    const userData = await loginUser({ email });
    localStorageServices.clearToken();
    localStorageServices.setCurrentUser(userData);
    dispatch(setCurrentUser(userData));
    history.push("/dashboard");
  };

  const debouncedSearchTerm = useDebounce(searchInput, 500);

  useEffect(() => {
    getPaymentPlans();
  }, [window.location.pathname]);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
    else if (!debouncedSearchTerm) getUsers();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (
      advanceSearch.advance_search_enabled ===
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER ||
      advanceSearch.advance_search_enabled ===
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM
    ) {
      getUsers();
      if (
        advanceSearch.advance_search_enabled ===
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM
      )
        setAdvanceSearch({
          ...advanceSearch,
          advance_search_enabled:
            SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
        });
    }
  }, [
    pagination.offset,
    pagination.limit,
    sortBy,
    sortOrder,
    advanceSearch.advance_search_enabled,
  ]);

  useEffect(() => {
    setUserlist(users.users);
    if (searchData && searchInput) setUserlist(searchData?.users);
    else if (advanceSearchData && advanceSearch?.advance_search_enabled !== "n")
      setUserlist(advanceSearchData?.users);

    if (showForm) {
      setUserInfo({
        name: "",
        company: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
        plan: "",
        coupon: "",
      });
    }
  }, [users, showForm, searchData, advanceSearchData]);

  const handleOnChange = async (e) => {
    const term = e.target.value;
    if (term.length >= 2) {
      setSearchInput(term);
    } else {
      if (term.length === 0) setSearchInput("");
    }
  };

  const isLoading =
    usersLoading || searchDataLoading || advanceSearchDataLoading;

  return (
    <div>
      {showForm && (
        <UsersForm
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          setShowForm={setShowForm}
          getUsers={getUsers}
          paymentPlans={paymentPlans}
          isLoading={isLoading}
          processSuccess={processSuccess}
          validationSchema={validationSchema}
        />
      )}

      {!showForm && (
        <>
          <Fade>
            <Card
              color="shadow"
              className={`${showDetail ? "d-none" : "d-block"}`}
            >
              <CardBody>
                <CardTitle>
                  <Row>
                    <Col sm={12} tag="h4">
                      <FontAwesomeIcon icon={faUser} className="mr-2" />
                      Users
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        color="primary"
                        className="my-1"
                        onClick={() => setShowForm(true)}
                      >
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                        New User
                      </Button>
                    </Col>

                    <Col xl={3} lg={"auto"} md={"auto"} sm={"auto"}>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <HeaderSearchInput onChange={handleOnChange} />
                        <Button
                          color="primary"
                          onClick={() => {
                            setShowAdvanceSearch(true);
                          }}
                          style={{
                            minWidth: "max-content",
                          }}
                        >
                          <FontAwesomeIcon icon={faFilter} className="mr-2" />
                          Advance Search
                        </Button>
                      </div>

                      <Collapse
                        isOpen={showAdvanceSearch}
                        delay={{
                          show: 5000,
                          hide: 5000,
                        }}
                      >
                        <AdvanceSearchUser
                          isOpen={showAdvanceSearch}
                          advanceSearch={advanceSearch}
                          setShowAdvanceSearch={setShowAdvanceSearch}
                          setAdvanceSearch={setAdvanceSearch}
                          getAdvanceSearchData={getAdvanceSearchData}
                          paymentPlans={paymentPlans}
                        />
                      </Collapse>
                    </Col>
                  </Row>
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                ></CardSubtitle>
                <CardText>
                  <UserList
                    userList={userList}
                    isLoading={isLoading}
                    leadDetail={leadDetail}
                    setshowDeatail={setshowDeatail}
                    showDetail={showDetail}
                    setLeadDetail={setLeadDetail}
                    getUsers={getUsers}
                    getSearchData={getSearchData}
                    searchInput={searchInput}
                    accountStatus={accountStatus}
                    loginAsUser={loginAsUser}
                    deleteUser={deleteUser}
                    removeSuccess={removeSuccess}
                    processSuccess={processSuccess}
                    setDeleteId={setDeleteId}
                    deleteId={deleteId}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                  />
                </CardText>
              </CardBody>
              <div className="px-4">
                {!searchInput &&
                  users?.count > SOURCES.DEFAULT_RECORDS_LISTING && (
                    <FormGroup>
                      <Paginate
                        setPagination={setPagination}
                        totalRecords={users?.count}
                        isLoading={isLoading}
                      />
                    </FormGroup>
                  )}
              </div>
            </Card>
          </Fade>
          <UserDetail
            showDetail={showDetail}
            setshowDeatail={setshowDeatail}
            leadDetail={leadDetail}
            setLeadDetail={setLeadDetail}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            paymentPlans={paymentPlans}
            loginAsUser={loginAsUser}
            setDeleteId={setDeleteId}
            deleteUser={deleteUser}
            removeSuccess={removeSuccess}
            getUsers={getUsers}
          />
        </>
      )}
    </div>
  );
};

export default Users;
