export const MSG_AUTORESPONDER = {
        SAVE: "Autoresponder saved succcessfully",
        DELETE: "Autoresponder deleted sucessfully"
}

export const MSG_ALERT = {
        SAVE: "Alert saved succcessfully",
        DELETE: "Alert deleted sucessfully"
}

export const MSG_DESTINATION = {
  SAVE_TRIPLESEAT: "Tripleseat configuration saved successfully",
  SAVE_CONVERSATE: "Conversate configuration saved successfully",
  SAVE_PLANNINGPOD: "Planning Pod configuration saved successfully",
  SAVE_HONEYBOOK: "Honey Book configuration saved successfully",
  SAVE_EVENTTEMPLE: "Event Temple configuration saved successfully",
  SAVE_17HATS: "17Hats configuration saved successfully",
  SAVE_BOOTHBOOK: "Booth Book configuration saved successfully",
  SAVE_CHECKCHERRY: "Check Cherry configuration saved successfully",
  SAVE_DJEVENTPLANNER: "DJ Event Planner configuration saved successfully",
  SAVE_RELEVENTFUL: "Releventful configuration saved successfully",
  SAVE_HUBSPOT: "Hubspot configuration saved successfully",
  SAVE_PERFECTVENUE: "Perfect Venue configuration saved successfully",
  SAVE_CATEREASE: "Caterease configuration saved successfully",
  SAVE_GOHIGHLEVEL: "Go High Level configuration saved successfully",
  SAVE_BLOOM: "Bloom configuration saved successfully",
  SAVE_MAILCHIMP: "MailChimp configuration saved successfully",
  SAVE_EVENT_INTELLIGENCE: "Event Intelligence configuration saved successfully",
  SAVE_NURTUREPRO: "NurturePro configuration saved successfully",
  SAVE_SMPLSYSTEM: "SMPL System configuration saved successfully",
  SAVE_VENUELEADPRO: "Venue Lead Pro configuration saved successfully",
};

export const MSG_ZAPIER = {
        SAVE: "Zapier Configration saved successfully",
}

export const MSG_LEADSOURCE = {
        SAVE: "Import Job saved successfully",
        DELETE: "Import Job deleted sucessfully"
}

export const MSG_PROFILE = {
        UPDATE: "Profile updated sucessfully",
        CANCLE_PLAN: "Plan cancelled sucessfully"
}

export const MSG_SUBSCRIPTION = {
        CANCLE_SUBSCRIPTION: "Subscription cancelled sucessfully",
}


export const MSG_DASHBORD = {
        CANCLE_JOB: "Job cancelled sucessfully",
}


export const MSG_INVOICES = {
        PAID: "Invoice paid succcessfully",
}


/************* REPORTS **********/

export const MSG_LEADS_VIA_CSV = {
        DELETE: "Lead deleted sucessfully",
}

/*********   ADMIN   *******/

export const MSG_USER = {
        SAVE: "User saved successfully",
        DELETE: "User deleted successfully",
        UPDATE: "User updated successfully",
        PAYMENTPLAN_CHANGE: "User payment plan changed successfully",
        TRIALPERIOD_CHANGE: "User trial period changed successfully",
        MAXLEADSUPLOAD_CHANGE: "User max leads upload via csv changed successfully",
        REACTIVATE_ACCOUNT: "User account activated successfully"
}

export const MSG_COUPONS = {
        DELETE: "Coupon deleted successfully",
        SAVE: "Coupon saved successfully"
}

export const MSG_CHANGE_USER_EMAIL = {
        CHANGE_EMAIL: "Email changed successfully"
}
