import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faMoneyBill,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import {
  Card,
  CardBody,
  CardText,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  CardFooter,
  Fade,
  Badge,
} from "reactstrap";
import LocalStorageService from "../utils/localStorageServices";
import CheckoutForm from "./stripe/CheckoutForm";
import useGet from "../services/useGet";
import usePost from "../services/usePost";
import { showUserNotification } from "../utils/functions";
import SubmitButton from "./common/submitButton";
import Header from "./common/header";

const localStorageServices = LocalStorageService.getService();

const Payment = ({ currentUser }) => {
  const isTrialUser = currentUser?.user?.isTrial;
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [paymentPlanId, setPaymentPlanId] = useState("");
  const [paymentPlanDescription, setPaymentPlanDescription] = useState("");
  const [paymentStripePlanId, setPaymentStripePlanId] = useState("");
  const [paymentPlanPrice, setPaymentPlanPrice] = useState(0);
  const [paymentPlanCode, setPaymentPlanCode] = useState("");
  const [currentPlan, setCurrentPlan] = useState("");
  const [couponId, setCouponId] = useState(null);

  const { callApi: getPaymentPlans, data: paymentPlans } = useGet({
    endpoint: `payment/plans`,
  });

  const { callApi: getCouponDetail, data: couponData } = useGet({
    endpoint: `coupon/detail/${couponId}`,
  });

  const { callApi: doResubscription } = usePost({
    endpoint: `/payment/resubscription`,
  });

  const { callApi: doSubscription } = usePost({
    endpoint: `/payment/subscription`,
  });

  const { callApi: updateUserPlan } = usePost({
    endpoint: `user/plan/update`,
  });

  useEffect(() => {
    getPaymentPlans();
  }, []);

  useEffect(() => {
    curentPlan();
  }, [paymentPlans]);

  useEffect(() => {
    if (couponId) getCouponDetail();
  }, [couponId]);

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const resultStripe = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          email: currentUser.user.email,
        },
      });

      if (resultStripe.error) {
        dispatch(showUserNotification(resultStripe.error.message, "danger"));
      } else {
        let res = null;
        /**
         * If TRIAL user then call SUBSCRIPTION otherwise call RESUBSCRIPTION
         */
        if (isTrialUser === "n") {
          res = await doResubscription({
            payment_method: resultStripe.paymentMethod.id,
            email: currentUser.user.email,
            name: currentUser.user.name,
            stripePlanId: paymentStripePlanId,
          });
        } else {
          res = await doSubscription({
            payment_method: resultStripe.paymentMethod.id,
            email: currentUser.user.email,
            name: currentUser.user.name,
            stripePlanId: paymentStripePlanId,
          });
        }
        // eslint-disable-next-line camelcase
        const { client_secret, status, subscription_id } = res;
        if (status === "requires_action") {
          stripe
            .confirmCardPayment(client_secret)
            .then(async (resultStripe) => {
              if (resultStripe.error) {
                console.log(resultStripe.error);
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc)
                dispatch(showUserNotification(resultStripe.error, "danger"));
              } else {
                await updateUserPlan({ paymentPlanId, subscription_id });

                currentUser.user.paymentPlanId = paymentPlanId;
                localStorageServices.setCurrentUser(currentUser);

                dispatch(
                  showUserNotification("Your payment has been received")
                );
                setTimeout(() => {
                  history.push("/profile");
                }, 2000);
              }
            });
        } else {
          // No additional information was needed
          await updateUserPlan({ paymentPlanId, subscription_id });
          currentUser.user.isTrial = "n";
          currentUser.user.paymentStatus = true;
          localStorageServices.setCurrentUser(currentUser);
          dispatch(showUserNotification("Your payment has been received"));
          setTimeout(() => {
            history.push("/profile");
          }, 2000);
        }
      }
    } catch (e) {
      dispatch(showUserNotification(e.response.data.data.error, "danger"));
    } finally {
      setLoading(false);
    }
  };

  const getPaymentPlanDetail = async (planId) => {
    setPaymentPlanId(planId);

    setPaymentPlanDescription("");
    paymentPlans.map((plan) => {
      if (planId === plan._id) {
        setPaymentStripePlanId(plan.stripePlanId);
        setPaymentPlanDescription(plan.planDetail);
        setPaymentPlanPrice(plan.planPrice);
        setPaymentPlanCode(plan.planCode);
      }
    });
  };

  const curentPlan = () => {
    paymentPlans &&
      paymentPlans.map((plan) => {
        if (currentUser?.user?.paymentPlanId?._id === plan._id) {
          setPaymentPlanId(plan._id);
          setCurrentPlan(plan.planName);
          setPaymentPlanPrice(plan.planPrice);
          setPaymentPlanCode(plan.planCode);
          setPaymentStripePlanId(plan.stripePlanId);
          setCouponId(currentUser?.user?.couponId);
        }
      });
  };

  const renewPayAsYouGoPlan = async () => {
    try {
      setLoading(true);
      await updateUserPlan({ paymentPlanId, subscription_id: "" });
      dispatch(showUserNotification("Renew plan successfully"));
    } catch (e) {
      dispatch(showUserNotification(e.response.data.data.error, "danger"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fade>
      <div>
        <Card color="shadow">
          <CardBody>
            <Header icon={faMoneyBill} title="Payment" />

            <CardText>
              <Form onSubmit={handleForm}>
                <FormGroup>
                  <Label for="public_key">Your Current Plan</Label>
                  <Input
                    id="current_plan"
                    name="current_plan"
                    type="text"
                    value={currentPlan}
                    autoComplete="off"
                    readOnly
                  />
                </FormGroup>

                {couponData && couponData?.code && (
                  <FormGroup>
                    <Label for="promo">Promo Applied</Label>
                    <Badge
                      color="danger"
                      className="ml-1"
                      style={{ padding: "5px 5px", borderRadius: "10px" }}
                    >
                      {couponData?.code}
                    </Badge>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label>Change Plan</Label>
                  <Input
                    name="confirm_password"
                    type="select"
                    className="form-control form-control-lg"
                    onChange={(e) => getPaymentPlanDetail(e.target.value)}
                    // invalid={errors.paymentPlanId}
                  >
                    <option value="">Choose Plan</option>
                    {paymentPlans &&
  paymentPlans.map((plan) => {
    if (plan.isPrivatePlan === 'n') {
      return (
        <option
          value={plan._id}
          selected={currentUser.user.paymentPlanId === plan._id}
        >
          {plan.planName}
        </option>
      );
    }
    return null; // Skip rendering if isPrivatePlan is not 'n'
  })}
                  </Input>
                  {/* <ValidationErrorHandling error={errors.paymentPlanId}/> */}
                </FormGroup>
                <FormGroup>{paymentPlanDescription}</FormGroup>

                <FormGroup>
                  <Label>Plan Price :</Label>
                  <Label className="ml-2">{paymentPlanPrice} $</Label>
                </FormGroup>

                {paymentPlanCode !== "p1" ? (
                  <>
                    <FormGroup>
                      <Label>Enter Card Details</Label>
                      <CheckoutForm />
                    </FormGroup>
                    <CardFooter>
                      <Button
                        type="submit"
                        color="primary"
                        onClick={handleForm}
                        disabled={loading}
                      >
                        {!loading && (
                          <FontAwesomeIcon
                            icon={faCreditCard}
                            className="mr-2"
                          />
                        )}
                        Pay Now
                        {loading && (
                          <Spinner
                            className="ml-2"
                            color="light"
                            size="sm"
                            children=""
                          />
                        )}
                      </Button>
                    </CardFooter>
                  </>
                ) : (
                  <CardFooter>
                    <SubmitButton
                      title="Renew"
                      icon={faSync}
                      isLoading={loading}
                      onClick={renewPayAsYouGoPlan}
                      cancleButton={false}
                      goBackButton={true}
                      goBackButtonOnClick={() => history.push("/profile")}
                    />
                  </CardFooter>
                )}
              </Form>
            </CardText>
          </CardBody>
        </Card>
      </div>
    </Fade>
  );
};
export default Payment;
