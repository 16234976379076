
const SOURCES = {
  ENV_STAGING: "staging",

  LEAD_EVENTECTIVE: "eventective",
  LEAD_EVENTUP: "eventup",
  LEAD_WEDDINGWIRE: "wedding_wire",
  LEAD_UNIQUEVENUES: "unique_venues",
  LEAD_PEERSPACE: "peer_space",
  LEAD_WEDDINGSPOT: "wedding_spot",
  LEAD_CONTACT_FORM: "Lead Form",
  LEAD_KNOT: "knot",
  LEAD_YELP: "yelp",

  DESTINATION_TRIPPLE_SEAT: "tripleseat",
  DESTINATION_CONVERSATE: "conversate",
  DESTINATION_PLANNINGPOD: "planning_pod",
  DESTINATION_HONEYBOOK: "honeybook",
  DESTINATION_EVENT_TEMPLE: "eventtemple",
  DESTINATION_17HATS: "17hats",
  DESTINATION_BOOTHBOOK: "boothbook",
  DESTINATION_CHECK_CHERRY: "checkcherry",
  DESTINATION_DJ_EVENT_PLANNER: "djeventplanner",
  DESTINATION_RELEVENTFUL: "releventful",
  DESTINATION_HUBSPOT: "hubspot",
  DESTINATION_PERFECT_VENUE: "perfectvenue",
  DESTINATION_MAILCHIMP: "mailchimp",
  DESTINATION_EVENT_INTELLIGENCE: "eventintelligence",

  ALERT_VIA_EMAIL: "email",
  ALERT_VIA_SMS: "sms",
  ALERT_VIA_BOTH: "both",

  USER_ROLE_ADMIN: "admin",
  USER_ROLE_AFFILIATE: "affiliate",

  USER_STATUS_ACTIVE: "active",
  USER_STATUS_SUSPENDED: "suspended",
  USER_STATUS_TRIAL_END: "trial_end",
  USER_STATUS_TRIAL: "trial",

  JOB_STATUS_RUNNING: "running",

  NOTIFICATION_READ: "read",
  NOTIFICATION_UNREAD: "unread",

  DISCOUNT_FIXED: "fixed",
  DISCOUNT_PERCENTAGE: "percentage",
  DISCOUNT_TRIAL: "trial",

  DEFAULT_RECORDS_LISTING: 20,
  DEFAULT_RECORDS_SEARCH: 100,

  ADVANCE_SEARCH_FIELDS: {
    SEARCH_TYPE_FILTER: "y",
    SEARCH_TYPE_NOT_FILTER: "n",
    SEARCH_TYPE_CLOSED_SEARCH_FORM: "closed_search",
    SEARCH_TYPE_DO_NOT_CALL_API_PARENT: "dont_call_api_on_parent",
  },

  PAYMENT_PLAN_CODE: {
    P_1: "p1",
    P_2: "p2",
    P_3: "p3",
    P_4: "p4",
    P_5: "p5",
  },

  HTTP_CODE_200: 200,
  HTTP_CODE_401: 401,
  HTTP_CODE_403: 403,
  HTTP_CODE_500: 500,

  AFFILIATE_ONBOARD_STATUS_PROGRESS: "progress",
  AFFILIATE_ONBOARD_STATUS_COMPLETED: "completed",

  STATUS_APPROVED: "approve",
  STATUS_REJECT: "reject",

  USER_PLAN_ENTERPRISE: "Enterprise",
};

export default SOURCES;