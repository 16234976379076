import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Table,
  Row,
  Col,
} from "reactstrap";
import { faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePost from "../../../services/usePost";
import {
  handleYupValidationErrors,
  showUserNotification,
  yupValidationSchema,
} from "../../../utils/functions";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import LocalStorageService from "../../../utils/localStorageServices";
import SOURCES from "../../../utils/constants";
import { showAlert } from "../../../redux/actions";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_TRIPLESEAT = process.env.REACT_APP_INTEGRATION_TRIPPLESEAT;

const validationSchema = yupValidationSchema({
  publicKey: { required: true, customName: "Public key" },
});

const localStorageServices = LocalStorageService.getService();

const TripleSeatForm = ({ saveIntegration, destinations }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [publicKey, setPublicKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [sendLeadsLimitTripleseat, setSendLeadsLimitTripleseat] = useState(0);
  const [locationId, setLocationId] = useState([]);
  const [locations, setLocations] = useState([]);

  const currentUser = localStorageServices.getCurrentUser();

  // console.log("current", currentUser.user.paymentPlanId.planCode)

  const paymentPlanCode = currentUser?.user?.paymentPlanId?.planCode;

  const { callApi: getTripleSeatLocations, isLoading } = usePost({
    endpoint: `integration/tripleseat-locations`,
  });

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const { integrationCompany, leadSentConfig } = dest;
        if (integrationCompany === DESTINATION_TRIPLESEAT) {
          setSendLeadsLimitTripleseat(leadSentConfig?.sentLimit);
          setPublicKey(dest.publicKey);
          setSecretKey(dest.secretKey);
          dest?.locations && setLocationId(dest?.locations);
          getLocations(dest?.publicKey);
        }
      }
    }
  }, [destinations]);

  const getLocations = async (key) => {
    if (key) {
      const locs = await getTripleSeatLocations({
        key,
      });
      if (locs) setLocations(locs);
      else dispatch(showUserNotification("Error getting TripleSeat locations", "danger"));
    }
  };

  const handleLocations = (e) => {
    const selectedLocationId = e.target.id;
    if (paymentPlanCode === SOURCES.PAYMENT_PLAN_CODE.P_5) {
      if (e.target.checked)
        setLocationId((PrevLocationId) => [
          ...PrevLocationId,
          selectedLocationId,
        ]);
      else {
        const temparr = locationId.filter((loc) => loc !== selectedLocationId);
        setLocationId(temparr);
      }
    } else {
      setLocationId([selectedLocationId]);
    }
  };

  const handleTripleseatForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          publicKey,
        },
        { abortEarly: false }
      );
      setErrors({});
      setLoading(true);
      const result = await saveIntegration(
        {
          publicKey,
          secretKey,
          sendLeadsLimit: sendLeadsLimitTripleseat,
          integrationCompany: DESTINATION_TRIPLESEAT,
          locations: locationId,
        },
        MSG_DESTINATION.SAVE_TRIPLESEAT,
        true,
        false
      );
      dispatch(
        showAlert({
          show: true,
          type: result?.status === SOURCES.HTTP_CODE_200 ? "success" : "danger",
          msg:
            result?.status === SOURCES.HTTP_CODE_200
              ? "Tripleseat configuration saved successfully"
              : result?.data?.error?.message,
        })
      );
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <DestinationHeader
      headerTitle="Tripleseat"
      headerImage="images/tripleseat.png"
    >
      <Form onSubmit={handleTripleseatForm} autoComplete="off">
        <FormGroup>
          <Label for="public_key">Public Key</Label>
          <Input
            id="public_key"
            name="public_key"
            placeholder="Public key"
            type="text"
            value={publicKey}
            onChange={(e) => setPublicKey(e.target.value)}
            invalid={errors.publicKey}
            autoComplete="off"
            autoFocus
          />
          <ValidationErrorHandling error={errors.publicKey} />
        </FormGroup>

        <FormGroup>
          <Button
            onClick={async () => await getLocations(publicKey)}
            className="mb-2"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner className="mr-2" color="light" size="sm" children="" />
            ) : (
              <FontAwesomeIcon icon={faSearchLocation} className="mr-2" />
            )}
            Get Locations
          </Button>
          {locations && locations?.length > 0 && (
            <Table responsive striped bordered hover size="sm">
              <thead>
                <tr style={{ whiteSpace: "nowrap" }}>
                  <th>Select Location</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {locations &&
                  locations?.map((loc, idx) => {
                    return (
                      <tr key={idx} id={idx} style={{ whiteSpace: "nowrap" }}>
                        <td>
                          <Input
                            style={{ margin: 0, position: "static" }}
                            checked={locationId?.includes(loc?.id?.toString())}
                            type="checkbox"
                            id={loc?.id}
                            onChange={handleLocations}
                          />
                        </td>
                        <td>{loc?.name}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </FormGroup>
        <Row>
          <Col lg={5} sm={12}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitTripleseat}
                onChange={(e) => setSendLeadsLimitTripleseat(e.target.value)}
                autoComplete="off"
                // className="w-50"
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handleTripleseatForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default TripleSeatForm;
