




import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Table,
  Row,
  Col,
} from "reactstrap";
import { faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  handleYupValidationErrors,
  showUserNotification,
  yupValidationSchema,
} from "../../../utils/functions";
import LocalStorageService from "../../../utils/localStorageServices";
import SOURCES from "../../../utils/constants";
import { showAlert } from "../../../redux/actions";

//bloom
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";
import usePost from "../../../services/usePost";




const DESTINATION_BLOOM = process.env.REACT_APP_INTEGRATION_BLOOM;

const validationSchema = yupValidationSchema({
  publicKey: { required: true, customName: "Public key" },
});

const localStorageServices = LocalStorageService.getService();

const BloomForm = ({ saveIntegration, destinations }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [publicKey, setPublicKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [sendLeadsLimitBloom, setSendLeadsLimitBloom] = useState(0);

  const [locationId, setLocationId] = useState([]);
  const [locations, setLocations] = useState([]);

  const currentUser = localStorageServices.getCurrentUser();

  // console.log("current", currentUser.user.paymentPlanId.planCode)

  const paymentPlanCode = currentUser?.user?.paymentPlanId?.planCode;

  const { callApi: getBloomLocations, isLoading } = usePost({
    endpoint: `integration/bloom-locations`,
  });

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const { integrationCompany, leadSentConfig } = dest;
        if (integrationCompany === DESTINATION_BLOOM) {
          setSendLeadsLimitBloom(leadSentConfig?.sentLimit);
          setPublicKey(dest.publicKey);
          setSecretKey(dest.secretKey);
          dest?.locations && setLocationId(dest?.locations);
          getLocations(dest?.publicKey);
        }
      }
    }
  }, [destinations]);

  const getLocations = async (key) => {
    console.log("key", key);
    if (key) {
      const locs = await getBloomLocations({
        key,
      });
      if (locs) setLocations(locs);
      else dispatch(showUserNotification("Error getting Bloom locations", "danger"));
    }
  };

  const handleLocations = (e) => {
    const selectedLocationId = e.target.id;
    if (paymentPlanCode === SOURCES.PAYMENT_PLAN_CODE.P_5) {
      if (e.target.checked)
        setLocationId((PrevLocationId) => [
          ...PrevLocationId,
          selectedLocationId,
        ]);
      else {
        const temparr = locationId.filter((loc) => loc !== selectedLocationId);
        setLocationId(temparr);
      }
    } else {
      setLocationId([selectedLocationId]);
    }
  };

  const handleBloomForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          publicKey,
        },
        { abortEarly: false }
      );
      setErrors({});
      setLoading(true);
      const result = await saveIntegration(
        {
          publicKey,
          secretKey,
          sendLeadsLimit: sendLeadsLimitBloom,
          integrationCompany: DESTINATION_BLOOM,
          locations: locationId,
        },
        MSG_DESTINATION.SAVE_BLOOM,
        true,
        false
      );
      dispatch(
        showAlert({
          show: true,
          type: result?.status === SOURCES.HTTP_CODE_200 ? "success" : "danger",
          msg:
            result?.status === SOURCES.HTTP_CODE_200
              ? "Bloom configuration saved successfully"
              : result?.data?.error?.message,
        })
      );
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <DestinationHeader
      headerTitle="Bloom"
      headerImage="images/bloom.png"
    >
      <Form onSubmit={handleBloomForm} autoComplete="off">
        <FormGroup>
          <Label for="public_key">Public Key</Label>
          <Input
            id="public_key"
            name="public_key"
            placeholder="Public key"
            type="text"
            value={publicKey}
            onChange={(e) => setPublicKey(e.target.value)}
            invalid={errors.publicKey}
            autoComplete="off"
            autoFocus
          />
          <ValidationErrorHandling error={errors.publicKey} />
        </FormGroup>

        <FormGroup>
          <Button
            onClick={async () => await getLocations(publicKey)}
            className="mb-2"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner className="mr-2" color="light" size="sm" children="" />
            ) : (
              <FontAwesomeIcon icon={faSearchLocation} className="mr-2" />
            )}
            Get Locations
          </Button>
          {locations && locations?.length > 0 && (
            <Table responsive striped bordered hover size="sm">
              <thead>
                <tr style={{ whiteSpace: "nowrap" }}>
                  <th>Select Location</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {locations &&
                  locations?.map((loc, idx) => {
                    return (
                      <tr key={idx} id={idx} style={{ whiteSpace: "nowrap" }}>
                        <td>
                          <Input
                            style={{ margin: 0, position: "static" }}
                            checked={locationId?.includes(loc?.id?.toString())}
                            type="checkbox"
                            id={loc?.id}
                            onChange={handleLocations}
                          />
                        </td>
                        <td>{loc?.name}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </FormGroup>
        <Row>
          <Col lg={5} sm={12}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitBloom}
                onChange={(e) => setSendLeadsLimitBloom(e.target.value)}
                autoComplete="off"
                // className="w-50"
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handleBloomForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default BloomForm;






























// import React, { useEffect, useState } from "react";
// import { Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";
// import { MSG_DESTINATION } from "../../../utils/message";
// import DestinationFooter from "./common/destinationFooter";
// import {
//   handleYupValidationErrors,
//   yupValidationSchema,
// } from "../../../utils/functions";
// import ValidationErrorHandling from "../../common/validationErrorHandling";
// import DestinationHeader from "./common/destinationHeader";

// const DESTINATION_BLOOM = process.env.REACT_APP_INTEGRATION_BLOOM;

// const validationSchema = yupValidationSchema({
//   bloomApiKey: { required: true, customName: "Api key" },
// });

// const BloomForm = ({ destinations, saveIntegration }) => {
//   const [loading, setLoading] = useState(false);
//   const [bloomApiKey, setBloomApiKey] = useState("");
//   const [sendLeadsLimitBloom, setSendLeadsLimitBloom] = useState(0);
//   const [errors, setErrors] = useState({});

//   useEffect(() => {
//     console.log(
//       "Javed Blooming->",
//       DESTINATION_BLOOM,
//       process.env.REACT_APP_INTEGRATION_BLOOM
//     );
//     if (destinations) {
//       for (let i = 0; i < destinations.length; i++) {
//         const dest = destinations[i];
//         const { integrationCompany, secretKey, leadSentConfig } = dest;
//         if (integrationCompany === DESTINATION_BLOOM) {
//           setBloomApiKey(secretKey);
//           setSendLeadsLimitBloom(leadSentConfig?.sentLimit);
//         }
//       }
//     }
//   }, [destinations]);

//   const handleBloomForm = async (e) => {
//     e.preventDefault();
//     try {
//       await validationSchema.validate({ bloomApiKey }, { abortEarly: false });
//       setLoading(true);
//       await saveIntegration(
//         {
//           secretKey: bloomApiKey,
//           sendLeadsLimit: sendLeadsLimitBloom,
//           integrationCompany: DESTINATION_BLOOM,
//         },
//         MSG_DESTINATION.SAVE_BLOOM
//       );
//       setErrors({});
//     } catch (e) {
//       setErrors(handleYupValidationErrors(e));
//     } finally {
//       setLoading(false);
//     }
//   };
//   return (
//     <DestinationHeader headerTitle="Bloom" headerImage="images/bloom.png">
//       <Form onSubmit={handleBloomForm} autoComplete="off">
//         <FormGroup>
//           <FormText color="info"></FormText>
//         </FormGroup>
//         <Row>
//           <Col lg={6}>
//             <FormGroup>
//               <Label for="api_key">API Key</Label>
//               <Input
//                 id="api_key"
//                 name="api_key"
//                 placeholder="API key"
//                 autocomplete="off"
//                 type="text"
//                 defaultValue={bloomApiKey}
//                 value={bloomApiKey}
//                 onChange={(e) => setBloomApiKey(e.target.value)}
//                 invalid={errors.bloomApiKey}
//                 autoComplete="off"
//               />
//               <ValidationErrorHandling error={errors.bloomApiKey} />
//             </FormGroup>
//           </Col>

//           <Col lg={6}>
//             <FormGroup>
//               <Label for="send_leads_initially">
//                 How Many Leads Do You Want To Send Initially?
//               </Label>
//               <Input
//                 id="send_leads_initially"
//                 name="send_leads_initially"
//                 placeholder="Total lead(s) send initially"
//                 type="text"
//                 value={sendLeadsLimitBloom}
//                 onChange={(e) => setSendLeadsLimitBloom(e.target.value)}
//                 autoComplete="off"
//                 autoFocus
//               />
//             </FormGroup>
//           </Col>
//         </Row>

//         <DestinationFooter handleSubmit={handleBloomForm} loading={loading} />
//       </Form>
//     </DestinationHeader>
//   );
// };

// export default BloomForm;