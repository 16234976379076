




import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Table,
  Row,
  Col,
} from "reactstrap";
import { faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  handleYupValidationErrors,
  showUserNotification,
  yupValidationSchema,
} from "../../../utils/functions";
import LocalStorageService from "../../../utils/localStorageServices";
import SOURCES from "../../../utils/constants";
import { showAlert } from "../../../redux/actions";

//bloom
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";
import usePost from "../../../services/usePost";




const DESTINATION_VENUELEADPRO = process.env.REACT_APP_INTEGRATION_VENUELEADPRO;

const validationSchema = yupValidationSchema({
  publicKey: { required: true, customName: "Public key" },
});

const localStorageServices = LocalStorageService.getService();

const VenueLeadsProForm = ({ saveIntegration, destinations }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [publicKey, setPublicKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [sendLeadsLimitVenueLeadsPro, setSendLeadsLimitVenueLeadsPro] = useState(0);

  const [locationId, setLocationId] = useState([]);
  const [locations, setLocations] = useState([]);

  const currentUser = localStorageServices.getCurrentUser();

  // console.log("current", currentUser.user.paymentPlanId.planCode)

  const paymentPlanCode = currentUser?.user?.paymentPlanId?.planCode;

  const { callApi: getVenueLeadProLocations, isLoading } = usePost({
    endpoint: `integration/venueLeadPro-locations`,
  });

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const { integrationCompany, leadSentConfig } = dest;
        if (integrationCompany === DESTINATION_VENUELEADPRO) {
          setSendLeadsLimitVenueLeadsPro(leadSentConfig?.sentLimit);
          setPublicKey(dest.publicKey);
          setSecretKey(dest.secretKey);
          dest?.locations && setLocationId(dest?.locations);
          getLocations(dest?.publicKey);
        }
      }
    }
  }, [destinations]);

  const getLocations = async (key) => {
    console.log("key", key);
    if (key) {
      const locs = await getVenueLeadProLocations({
        key,
      });
      if (locs) setLocations(locs);
      else dispatch(showUserNotification("Error getting VenueLeadPro locations", "danger"));
    }
  };

  const handleLocations = (e) => {
    const selectedLocationId = e.target.id;
    if (paymentPlanCode === SOURCES.PAYMENT_PLAN_CODE.P_5) {
      if (e.target.checked)
        setLocationId((PrevLocationId) => [
          ...PrevLocationId,
          selectedLocationId,
        ]);
      else {
        const temparr = locationId.filter((loc) => loc !== selectedLocationId);
        setLocationId(temparr);
      }
    } else {
      setLocationId([selectedLocationId]);
    }
  };

  const handleVenueLeadProForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          publicKey,
        },
        { abortEarly: false }
      );
      setErrors({});
      setLoading(true);
      const result = await saveIntegration(
        {
          publicKey,
          secretKey,
          sendLeadsLimit: sendLeadsLimitVenueLeadsPro,
          integrationCompany: DESTINATION_VENUELEADPRO,
          locations: locationId,
        },
        MSG_DESTINATION.SAVE_VENUELEADPRO,
        true,
        false
      );
      dispatch(
        showAlert({
          show: true,
          type: result?.status === SOURCES.HTTP_CODE_200 ? "success" : "danger",
          msg:
            result?.status === SOURCES.HTTP_CODE_200
              ? "VenueLeadPro configuration saved successfully"
              : result?.data?.error?.message,
        })
      );
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <DestinationHeader
      headerTitle="VenueLeadPro"
      headerImage="images/VenueLead.png"
    >
      <Form onSubmit={handleVenueLeadProForm} autoComplete="off">
        <FormGroup>
          <Label for="public_key">Public Key</Label>
          <Input
            id="public_key"
            name="public_key"
            placeholder="Public key"
            type="text"
            value={publicKey}
            onChange={(e) => setPublicKey(e.target.value)}
            invalid={errors.publicKey}
            autoComplete="off"
            autoFocus
          />
          <ValidationErrorHandling error={errors.publicKey} />
        </FormGroup>

        <FormGroup>
          <Button
            onClick={async () => await getLocations(publicKey)}
            className="mb-2"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner className="mr-2" color="light" size="sm" children="" />
            ) : (
              <FontAwesomeIcon icon={faSearchLocation} className="mr-2" />
            )}
            Get Locations
          </Button>
          {locations && locations?.length > 0 && (
            <Table responsive striped bordered hover size="sm">
              <thead>
                <tr style={{ whiteSpace: "nowrap" }}>
                  <th>Select Location</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {locations &&
                  locations?.map((loc, idx) => {
                    return (
                      <tr key={idx} id={idx} style={{ whiteSpace: "nowrap" }}>
                        <td>
                          <Input
                            style={{ margin: 0, position: "static" }}
                            checked={locationId?.includes(loc?.id?.toString())}
                            type="checkbox"
                            id={loc?.id}
                            onChange={handleLocations}
                          />
                        </td>
                        <td>{loc?.name}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </FormGroup>
        <Row>
          <Col lg={5} sm={12}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitVenueLeadsPro}
                onChange={(e) => setSendLeadsLimitVenueLeadsPro(e.target.value)}
                autoComplete="off"
                // className="w-50"
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handleVenueLeadProForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default VenueLeadsProForm;





















// import React, { useEffect, useState } from "react";
// import { Col, Form, FormGroup, FormText, Input, Label, Row, Table } from "reactstrap";
// import { MSG_DESTINATION } from "../../../utils/message";
// import DestinationFooter from "./common/destinationFooter";
// import { handleYupValidationErrors, yupValidationSchema } from "../../../utils/functions";
// import ValidationErrorHandling from "../../common/validationErrorHandling";
// import DestinationHeader from "./common/destinationHeader";
// import axios from "axios";
// import { use } from "react";

// const DESTINATION_VENUELEADPRO = process.env.REACT_APP_INTEGRATION_VENUELEADPRO;

// const validationSchema = yupValidationSchema({
//   venueLeadsProApiKey: { required: true, customName: "Api key" },
// });

// const VenueLeadsProForm = ({ destinations, saveIntegration }) => {
//   const [loading, setLoading] = useState(false);
//   const [venueLeadsProApiKey, setVenueLeadsProApiKey] = useState("");
//   const [sendLeadsLimitVenueLeadsPro, setSendLeadsLimitVenueLeadsPro] = useState(0);
//   const [locations, setLocations] = useState([]);
//   const [errors, setErrors] = useState({});

//   // useEffect(() => {
//   //   if (destinations) {
//   //     for (let i = 0; i < destinations.length; i++) {
//   //       const dest = destinations[i];
//   //       const { integrationCompany, secretKey, leadSentConfig } = dest;
//   //       if (integrationCompany === DESTINATION_VENUELEADPRO) {
//   //         venueLeadsProApiKey(secretKey);
//   //         setSendLeadsLimitVenueLeadsPro(leadSentConfig?.sentLimit);
//   //       }
//   //     }
//   //   }
//   // }, [destinations]);


// const getLocation = async () => {
//   const apiUrl = "https://rest.gohighlevel.com/v1/locations/";
//   const apiKey =
//     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6ImpINWRWNlNUS0VFWmoxdGxWd20wIiwiY29tcGFueV9pZCI6IktrbEtremV5ckRReGVwMkxLSHhWIiwidmVyc2lvbiI6MSwiaWF0IjoxNjk5OTAyMjc0MjUzLCJzdWIiOiJ1c2VyX2lkIn0.z_OLAK515DoaZJNx-EYMvoP0ngS8QwA6Hx7fiP-vl5I"; // Replace with your actual API Key

//   try {
//     const response = await axios.get(apiUrl, {
//       headers: {
//         Authorization: `Bearer ${apiKey}`, // Authentication Token
//         "Content-Type": "application/json", // Optional for JSON data
//       },
//     });
// setLocations(response.data.locations);
//     // console.log("Response Data:", response.data); // Your actual data
//   } catch (error) {
//     console.error("Failed to fetch location data:", error);
//   }
// };

// // getLocation();
// useEffect(() => {
//   getLocation();
// }, [destinations]);




// // console.log("locationss", locations);




//   const handleVenueLeadProForm = async (e) => {
//     e.preventDefault();
//     try {
//       await validationSchema.validate({ venueLeadsProApiKey }, { abortEarly: false });
//       setLoading(true);
//       await saveIntegration(
//         {
//           secretKey: venueLeadsProApiKey,
//           sendLeadsLimit: sendLeadsLimitVenueLeadsPro,
//           integrationCompany: DESTINATION_VENUELEADPRO,
//         },
//         MSG_DESTINATION.SAVE_VENUELEADPRO
//       );
//       setErrors({});
//     } catch (e) {
//       setErrors(handleYupValidationErrors(e));
//     } finally {
//       setLoading(false);
//     }
//   };

//    {
//      /* Dummy locations data */
//    }
//   //  const locations = [
//   //    { id: 1, name: "France" },
//   //    { id: 2, name: "New York" },
//   //    { id: 3, name: "Finland" },
//   //    { id: 4, name: "Norway" },
//   //    { id: 5, name: "Sweden" },
//   //  ];

//   return (
//     <DestinationHeader headerTitle="VenueLeadPro" headerImage="images/venueLead.png">
//       <Form onSubmit={handleVenueLeadProForm} autoComplete="off">
//         <FormGroup>
//           <FormText color="info">
//             <strong> NOTE:</strong> Each location has its own API key. You can get it from Setting-&gt;
//             Bussiness Profile for that specific location.
//           </FormText>
//         </FormGroup>

//         {/* Table for locations */}
//         <Table responsive hover>
//           <thead>
//             <tr>
//               <th>API Key</th>
//               <th>Select</th>
//               <th>Location Name</th>
//               <th>Location Address</th>
//             </tr>
//           </thead>
//           <tbody>
//             {locations.map((location, index) => (
//               <tr key={index}>
//                 <td>
//                   <Input
//                     id={`api_key_${location.id}`}
//                     name={`api_key_${location.id}`}
//                     placeholder={`Get API key from VenueLeadPro Application for location: ${location.name}`}
//                     autocomplete="off"
//                     type="text"
//                     defaultValue={venueLeadsProApiKey[location.id] || ""}
//                     value={venueLeadsProApiKey[location.id] || ""}
//                     onChange={(e) =>
//                       setVenueLeadsProApiKey({ ...venueLeadsProApiKey, [location.id]: e.target.value })
//                     }
//                     invalid={errors[`api_key_${location.id}`]}
//                     autoComplete="off"
//                   />
//                 </td>
//                 <td>
//                   <Input
//                     style={{ margin: 0, position: "static" }}
//                     checked={location.id.toString()} // Check if location ID is in selectedLocations
//                     // checked={selectedLocations?.includes(location.id.toString())} // Check if location ID is in selectedLocations
//                     type="checkbox"
//                     id={location.id}
//                     // onChange={handleLocations} // Handle checkbox change
//                   />
//                 </td>
//                 <td>{location.name}</td>
//                 <td>{location.address}</td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//         <Row>
//           <Col lg={5} sm={12}>
//             <FormGroup>
//               <Label for="send_leads_initially">How Many Leads Do You Want To Send Initially?</Label>
//               <Input
//                 id="send_leads_initially"
//                 name="send_leads_initially"
//                 placeholder="Total lead(s) send initially"
//                 type="text"
//                 min={5}
//                 value={sendLeadsLimitVenueLeadsPro}
//                 onChange={(e) => setSendLeadsLimitVenueLeadsPro(e.target.value)}
//                 autoComplete="off"
//                 // className="w-50"
//               />
//             </FormGroup>
//           </Col>
//         </Row>

//         <DestinationFooter handleSubmit={handleVenueLeadProForm} loading={loading} />
//       </Form>
//     </DestinationHeader>
//   );
// };

// export default VenueLeadsProForm;
