import { useEffect, useState } from "react";
import { Card, CardBody, Fade } from "reactstrap";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import useGet from "../services/useGet";
import usePost from "../services/usePost";
import TripleSeatForm from "./forms/destinations/tripleseat";
import ConversateForm from "./forms/destinations/conversate";
import PlanningpodForm from "./forms/destinations/planningpod";
import HoneybookForm from "./forms/destinations/honeybook";
import EventtempleForm from "./forms/destinations/eventtemple";
import SeventeenhatsForm from "./forms/destinations/17hats";
import BoothbookForm from "./forms/destinations/boothbook";
import CheckCherryForm from "./forms/destinations/checkCherry";
import DJEventPlannerForm from "./forms/destinations/djEventPlanner";
import GenericTabs from "./common/tab";
import ReleventfulForm from "./forms/destinations/releventful";
import HubspotForm from "./forms/destinations/hubspot";
import PerfectVenueForm from "./forms/destinations/perfectVenue";
import Header from "./common/header";
import CatereaseForm from "./forms/destinations/caterease";
import GoHighLevelForm from "./forms/destinations/goHighLevel";
import MailChimpForm from "./forms/destinations/mailChimp";
import BloomForm from "./forms/destinations/bloom";
import ProfitfloForm from "./forms/destinations/profitflo";
import VenueForm from "./forms/destinations/venue";
import NurtureProForm from "./forms/destinations/nurturePro";
import EventIntelligenceForm from "./forms/destinations/eventIntelligence";
import SmplSystemForm from "./forms/destinations/smplSystem";
import VenueLeadsProForm from "./forms/destinations/venueLeadPro";
import SOURCES from "../utils/constants";

const Destinations = () => {
  const [toggleActivation, setToggleActivation] = useState(true);

  const { callApi: saveIntegration } = usePost({
    endpoint: `integration`,
  });

  const { callApi: getUserDestinations, data: destinationsData } = useGet({
    endpoint: `integration`,
  });

  const { callApi: getZapierConfigration, data: zapierSetup } = useGet({
    endpoint: `zapier`,
  });


  //testttt

  const {
    callApi: getPayments,
    data: payments,
    isLoading: getPaymentLoading,
  } = useGet({
    endpoint: `payment/get-paymentHistory`, // API endpoint for payment history
  });

  // Local state for storing the fetched payment history
  const [history, setHistory] = useState([]);


  // Update `history` state when `payments` data changes
  useEffect(() => {
    setHistory(payments); // Sync the fetched data to the state
  }, [payments]);

  // Debugging output
  console.log("historyyyyy    ", history, history.planName);

  useEffect(() => {
    const getDestinations = async () => await getUserDestinations();
    getDestinations();
    getZapierConfigration();
    getPayments();



  }, []);

  const isEnterprisePlan = history && history.planName === SOURCES.USER_PLAN_ENTERPRISE;


  // Initialize an empty tabs array
  let tabs = [{
    tabId: "1",
    title: "Tripleseat",
    icon: "images/tripleseat.png",
    className: "shadow",
    component: (
      <TripleSeatForm
        saveIntegration={saveIntegration}
        destinations={destinationsData}
        toggleActivation={toggleActivation}
        setToggleActivation={setToggleActivation}
      />
    ),
  },
  {
    tabId: "2",
    title: "Conversate",
    icon: "images/conversate.png",
    className: "shadow",
    component: (
      <ConversateForm saveIntegration={saveIntegration} destinations={destinationsData} />
    ),
  },
  {
    tabId: "3",
    title: "PlanningPod",
    icon: "images/planningpod.png",
    className: "shadow",
    component: (
      <PlanningpodForm saveIntegration={saveIntegration} destinations={destinationsData} />
    ),
  },
  {
    tabId: "4",
    title: "HoneyBook",
    icon: "images/honeybook.png",
    className: "shadow",
    component: (
      <HoneybookForm
        saveIntegration={saveIntegration}
        destinations={destinationsData}
        zapierSetup={zapierSetup}
      />
    ),
  },
  {
    tabId: "5",
    title: "EventTemple",
    icon: "images/eventtemple.png",
    className: "shadow",
    component: (
      <EventtempleForm saveIntegration={saveIntegration} destinations={destinationsData} />
    ),
  },
  {
    tabId: "6",
    title: "17Hats",
    icon: "images/17hats.png",
    className: "shadow",
    component: (
      <SeventeenhatsForm saveIntegration={saveIntegration} destinations={destinationsData} />
    ),
  },
  {
    tabId: "7",
    title: "BoothBook",
    icon: "images/boothbook.png",
    className: "shadow",
    component: (
      <BoothbookForm saveIntegration={saveIntegration} destinations={destinationsData} />
    ),
  },
  {
    tabId: "8",
    title: "Check Cherry",
    icon: "images/checkcherry.png",
    className: "shadow",
    component: (
      <CheckCherryForm saveIntegration={saveIntegration} destinations={destinationsData} />
    ),
  },
  {
    tabId: "9",
    title: "DJ Event Planner",
    icon: "images/djevent.jpeg",
    className: "shadow",
    component: (
      <DJEventPlannerForm saveIntegration={saveIntegration} destinations={destinationsData} />
    ),
  },
  {
    tabId: "10",
    title: "Releventful",
    icon: "images/releventful.png",
    className: "shadow",
    component: (
      <ReleventfulForm saveIntegration={saveIntegration} destinations={destinationsData} />
    ),
  },
  {
    tabId: "11",
    title: "Hubspot",
    icon: "images/hubspot.png",
    className: "shadow",
    component: <HubspotForm saveIntegration={saveIntegration} destinations={destinationsData} />,
  },
  {
    tabId: "12",
    title: "Perfect Venue",
    icon: "images/perfectvenue.png",
    className: "shadow",
    component: (
      <PerfectVenueForm saveIntegration={saveIntegration} destinations={destinationsData} />
    ),
  },
  {
    tabId: "13",
    title: "Caterease",
    icon: "images/caterease.png",
    className: "shadow",
    component: (
      <CatereaseForm saveIntegration={saveIntegration} destinations={destinationsData} />
    ),
  },

  {
    tabId: "19",
    title: "MailChimp",
    icon: "images/mailchimp.png",
    className: "shadow",
    component: (
      <MailChimpForm saveIntegration={saveIntegration} destinations={destinationsData} />
    ),
  },
  {
    tabId: "20",
    title: "Event Intelligence",
    icon: "images/eventIntelligence.png",
    className: "shadow",
    component: (
      <EventIntelligenceForm saveIntegration={saveIntegration} destinations={destinationsData} />
    ),
  },
  {
    tabId: "21",
    title: "SMPL System",
    icon: "images/SMPLsystem.png",
    className: "shadow",
    component: (
      <SmplSystemForm saveIntegration={saveIntegration} destinations={destinationsData} />
    ),
  },

];

  // Populate the tabs array only if the enterprise plan is detected
  // if (isEnterprisePlan) {
    console.log("Enterprise plan detected");
    tabs.push(


      //ghl

      {
        tabId: "14",
        title: "GoHighLevel",
        icon: "images/gohighlevel.png",
        className: "shadow",
        component: (
          <GoHighLevelForm saveIntegration={saveIntegration} destinations={destinationsData} />
        ),
      },
      {
        tabId: "22",
        title: "Venue Lead Pro",
        icon: "images/VenueLead.png",
        className: "shadow",
        component: (
          <VenueLeadsProForm saveIntegration={saveIntegration} destinations={destinationsData} />
        ),
      },

      {
        tabId: "15",
        title: "Bloom",
        icon: "images/bloom.png",
        className: "shadow",
        component: <BloomForm saveIntegration={saveIntegration} destinations={destinationsData} />,

      },

      {
        tabId: "16",
        title: "Profitflo",
        icon: "images/profitflo.png",
        className: "shadow",
        component: (
          <ProfitfloForm saveIntegration={saveIntegration} destinations={destinationsData} />
        ),
      },
      {
        tabId: "17",
        title: "Venue",
        icon: "images/venue.png",
        className: "shadow",
        component: <VenueForm saveIntegration={saveIntegration} destinations={destinationsData} />,
      },
      {
        tabId: "18",
        title: "NurturePro",
        icon: "images/NurturePro.png",
        className: "shadow",
        component: (
          <NurtureProForm saveIntegration={saveIntegration} destinations={destinationsData} />
        ),
      },
    
    )
  //}




  return (
    // <Fade>
    //   <Card>
    //     <CardBody>
    //       <Header icon={faMapMarker} title="Destinations" />

    //       <GenericTabs
    //         tabs={[
    //           {
    //             tabId: "1",
    //             title: "Tripleseat",
    //             icon: "images/tripleseat.png",
    //             className: "shadow",
    //             component: (
    //               <TripleSeatForm
    //                 saveIntegration={saveIntegration}
    //                 destinations={destinationsData}
    //                 toggleActivation={toggleActivation}
    //                 setToggleActivation={setToggleActivation}
    //               />
    //             ),
    //           },
    //           {
    //             tabId: "2",
    //             title: "Conversate",
    //             icon: "images/conversate.png",
    //             className: "shadow",
    //             component: (
    //               <ConversateForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "3",
    //             title: "PlanningPod",
    //             icon: "images/planningpod.png",
    //             className: "shadow",
    //             component: (
    //               <PlanningpodForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "4",
    //             title: "HoneyBook",
    //             icon: "images/honeybook.png",
    //             className: "shadow",
    //             component: (
    //               <HoneybookForm
    //                 saveIntegration={saveIntegration}
    //                 destinations={destinationsData}
    //                 zapierSetup={zapierSetup}
    //               />
    //             ),
    //           },
    //           {
    //             tabId: "5",
    //             title: "EventTemple",
    //             icon: "images/eventtemple.png",
    //             className: "shadow",
    //             component: (
    //               <EventtempleForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "6",
    //             title: "17Hats",
    //             icon: "images/17hats.png",
    //             className: "shadow",
    //             component: (
    //               <SeventeenhatsForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "7",
    //             title: "BoothBook",
    //             icon: "images/boothbook.png",
    //             className: "shadow",
    //             component: (
    //               <BoothbookForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "8",
    //             title: "Check Cherry",
    //             icon: "images/checkcherry.png",
    //             className: "shadow",
    //             component: (
    //               <CheckCherryForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "9",
    //             title: "DJ Event Planner",
    //             icon: "images/djevent.jpeg",
    //             className: "shadow",
    //             component: (
    //               <DJEventPlannerForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "10",
    //             title: "Releventful",
    //             icon: "images/releventful.png",
    //             className: "shadow",
    //             component: (
    //               <ReleventfulForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "11",
    //             title: "Hubspot",
    //             icon: "images/hubspot.png",
    //             className: "shadow",
    //             component: <HubspotForm saveIntegration={saveIntegration} destinations={destinationsData} />,
    //           },
    //           {
    //             tabId: "12",
    //             title: "Perfect Venue",
    //             icon: "images/perfectvenue.png",
    //             className: "shadow",
    //             component: (
    //               <PerfectVenueForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "13",
    //             title: "Caterease",
    //             icon: "images/caterease.png",
    //             className: "shadow",
    //             component: (
    //               <CatereaseForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },

    //           //GHL destionation start

    //           {
    //             tabId: "14",
    //             title: "GoHighLevel",
    //             icon: "images/gohighlevel.png",
    //             className: "shadow",
    //             component: (
    //               <GoHighLevelForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "22",
    //             title: "Venue Lead Pro",
    //             icon: "images/VenueLead.png",
    //             className: "shadow",
    //             component: (
    //               <VenueLeadsProForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },

    //           {
    //             tabId: "15",
    //             title: "Bloom",
    //             icon: "images/bloom.png",
    //             className: "shadow",
    //             component: <BloomForm saveIntegration={saveIntegration} destinations={destinationsData} />,

    //           },

    //           {
    //             tabId: "16",
    //             title: "Profitflo",
    //             icon: "images/profitflo.png",
    //             className: "shadow",
    //             component: (
    //               <ProfitfloForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "17",
    //             title: "Venue",
    //             icon: "images/venue.png",
    //             className: "shadow",
    //             component: <VenueForm saveIntegration={saveIntegration} destinations={destinationsData} />,
    //           },
    //           {
    //             tabId: "18",
    //             title: "NurturePro",
    //             icon: "images/NurturePro.png",
    //             className: "shadow",
    //             component: (
    //               <NurtureProForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "19",
    //             title: "MailChimp",
    //             icon: "images/mailchimp.png",
    //             className: "shadow",
    //             component: (
    //               <MailChimpForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "20",
    //             title: "Event Intelligence",
    //             icon: "images/eventIntelligence.png",
    //             className: "shadow",
    //             component: (
    //               <EventIntelligenceForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //           {
    //             tabId: "21",
    //             title: "SMPL System",
    //             icon: "images/SMPLsystem.png",
    //             className: "shadow",
    //             component: (
    //               <SmplSystemForm saveIntegration={saveIntegration} destinations={destinationsData} />
    //             ),
    //           },
    //         ]}
    //       />
    //     </CardBody>
    //   </Card>
    // </Fade>

    <Fade>
      <Card>
        <CardBody>
          <Header icon={faMapMarker} title="Destinations" />
          <GenericTabs tabs={tabs} />
        </CardBody>
      </Card>
    </Fade>
  );
};

export default Destinations;
